'use client';

import { Flex, Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import ReactCardFlip from 'react-card-flip';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { PartnerGridDimensions } from './PartnerGridDimensions';
import MotionCard from '~/app/components/card/MotionCard';
const Partner = ({
  PartnerComponent,
  delay
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const {
    fullHeight,
    fullWidth
  } = PartnerGridDimensions();
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  return <MotionCard data-sentry-element="MotionCard" data-sentry-component="Partner" data-sentry-source-file="Partner.tsx">
      <Flex alignItems="center" justifyContent="center" p={{
      base: '1',
      lg: '4'
    }} w={fullWidth} cursor="pointer" data-aos="fade-up" border="1px solid" borderColor="darkborder" data-aos-delay={delay} data-aos-anchor-placement="top-bottom" h={fullHeight} onClick={handleClick} boxShadow="0 0 12px #000" backdropFilter="blur(24px)" borderRadius="32px" _hover={{
      '.chevron-icon': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    }} data-sentry-element="Flex" data-sentry-source-file="Partner.tsx">
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" data-sentry-element="ReactCardFlip" data-sentry-source-file="Partner.tsx">
          <div onClick={handleClick}>
            {PartnerComponent.front}{' '}
            <Box className="chevron-icon" position="absolute" bottom="5px" right="5px" bg="rgba(255, 255, 255, 0.02)" backdropFilter="blur(24px)" borderRadius="24px" p="0" opacity="0" transform="translateY(10px)" transition="opacity 0.2s ease, transform 0.2s ease" data-sentry-element="Box" data-sentry-source-file="Partner.tsx">
              <ChevronRightIcon boxSize={8} color="highlight" data-sentry-element="ChevronRightIcon" data-sentry-source-file="Partner.tsx" />
            </Box>
          </div>
          <div onClick={handleClick}>{PartnerComponent.back}</div>
        </ReactCardFlip>
      </Flex>
    </MotionCard>;
};
Partner.propTypes = {
  PartnerComponent: PropTypes.shape({
    front: PropTypes.node.isRequired,
    back: PropTypes.node.isRequired
  }).isRequired,
  delay: PropTypes.number.isRequired
};
export default Partner;