'use client';

import React from 'react';
import { Box, SimpleGrid, Flex, Text, useTheme } from '@chakra-ui/react';
import Link from 'next/link';
import TwitterIcon from '~/app/components/icons/Social/TwitterIcon';
import DiscordIcon from '~/app/components/icons/Social/DiscordIcon';
import RedditIcon from '~/app/components/icons/Social/RedditIcon';
import MediumIcon from '~/app/components/icons/Social/MediumIcon';
import TelegramIcon from '~/app/components/icons/Social/TelegramIcon';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
function Socials() {
  const networkConfig = useNetworkConfig();
  const theme = useTheme();
  const socialLinks = [{
    href: networkConfig.socialLinks.twitter,
    icon: <TwitterIcon />,
    text: 'Twitter'
  }, {
    href: networkConfig.socialLinks.discord,
    icon: <DiscordIcon />,
    text: 'Discord'
  }, {
    href: networkConfig.socialLinks.telegram,
    icon: <TelegramIcon />,
    text: 'Telegram'
  }, {
    href: networkConfig.socialLinks.reddit,
    icon: <RedditIcon />,
    text: 'Reddit'
  }, {
    href: networkConfig.socialLinks.medium,
    icon: <MediumIcon />,
    text: 'Medium'
  }];
  return <SimpleGrid columns={2} spacing={2} data-sentry-element="SimpleGrid" data-sentry-component="Socials" data-sentry-source-file="Socials.tsx">
      {socialLinks.map((link, index) => <Link key={index} href={link.href} target="_blank" rel="noopener noreferrer">
          <Flex alignItems="center" p={2} transition="all 0.3s" _hover={{
        opacity: 0.6
      }}>
            <Box width="3rem" display="flex" alignItems="center">
              {link.icon}
            </Box>
            <Text transition="color 0.3s" _hover={{
          color: theme.colors.primary
        }}>
              {link.text}
            </Text>
          </Flex>
        </Link>)}
    </SimpleGrid>;
}
export default Socials;