'use client';

import { Flex, Grid, Text } from '@chakra-ui/react';
import OptimismFoundation from './OptimismFoundation';
import StatCard from './StatCard';
import numeral from 'numeral';
import SectionShell from '../../components/SectionShell';
import { useChainId } from 'wagmi';
import { BaseNetworkConfig } from '~/lib/config/base-config';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import SectionTitleText from '../../components/SectionTitleText';
import { CHAIN_MAIN, CHAIN_MAIN_CHAIN_NAME, CHAIN_MAIN_NAME } from '~/constants';
export default function Section5Stats() {
  const chainId = useChainId() || CHAIN_MAIN;
  const {
    data,
    loading
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });

  // protocolData?.allTimeV2?.totalFeesUSD + protocolData?.allTimeV3?.totalFeesUSD,
  const protocolData = data?.protocolData;
  const allTimeVolume = numeral((protocolData?.allTimeV3?.totalVolumeUSD || 0) + (protocolData?.allTimeV2?.totalVolumeUSD || 0)).format('$0.0a').toUpperCase() || '0';
  const allTimeFees = numeral((protocolData?.allTimeV3?.totalFeesUSD || 0) + (protocolData?.allTimeV2?.totalFeesUSD || 0)).format('$0.0a').toUpperCase() || '0';
  const todaysVolume = numeral(protocolData?.swapVolume24h).format('$0.0a').toUpperCase() || '0';
  return <SectionShell data-sentry-element="SectionShell" data-sentry-component="Section5Stats" data-sentry-source-file="index.tsx">
      <Grid templateColumns={{
      base: '1fr',
      lg: '1fr 1fr'
    }} templateRows="1fr" gap={6} p={4} w="100%" h="100%" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Flex direction="column" flex="1" justify="space-between" align="flex-start" w={{
        base: '100%',
        lg: '100%'
      }} p={4} h="100%" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Flex h="100%" align="flex-start" direction="column" justify="flex-start" data-aos-id-stats data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <SectionTitleText aosAnchor="[data-aos-id-stats]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">
              We&apos;ve been here.{' '}
            </SectionTitleText>
            <Text fontSize="24px" mt="-1rem" ml="8px" color="highlight" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              Since the start.{' '}
            </Text>
          </Flex>
          <Flex h="100%" w={{
          base: '100%',
          lg: '75%'
        }} align="flex-end" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <Text fontSize={{
            base: '16px',
            lg: '18px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
              {CHAIN_MAIN_NAME} has been around as long as {CHAIN_MAIN_CHAIN_NAME}. Launched in the
              earliest days of the network, it now serves as a leading liquidity hub and onchain
              market for hundreds of thousands of users.
            </Text>
          </Flex>
        </Flex>
        <Grid templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)'
      }} gap={4} flex={1} w={{
        base: '100%',
        lg: '100%'
      }} data-aos-id-statcard data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <StatCard topText="All time volume" bottomText={allTimeVolume} data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />
          <StatCard topText={`${CHAIN_MAIN_NAME} users`} bottomText="560k" data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />
          <StatCard topText="All-time fees" bottomText={allTimeFees} data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />
          <StatCard topText="Today's volume" bottomText={todaysVolume} bottomTextColor="highlight" data-sentry-element="StatCard" data-sentry-source-file="index.tsx" />
        </Grid>
      </Grid>
      <Flex w="100%" mt="1rem" justify="flex-end" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <OptimismFoundation data-sentry-element="OptimismFoundation" data-sentry-source-file="index.tsx" />
      </Flex>
    </SectionShell>;
}