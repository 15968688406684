import { Flex, Text } from '@chakra-ui/react';
import MotionCard from '~/app/components/card/MotionCard';
interface StatCardProps {
  topText: string;
  bottomText: string;
  delay?: string;
  fade?: string;
  bottomTextColor?: string;
}
const StatCard = ({
  topText,
  bottomText,
  bottomTextColor = '#fff',
  delay = '0',
  // dffault delay
  fade = 'fade-up'
}: StatCardProps) => {
  return <MotionCard data-sentry-element="MotionCard" data-sentry-component="StatCard" data-sentry-source-file="StatCard.tsx">
      <Flex direction="column" justify="space-between" alignItems="flex-start" borderRadius="18px" border="1px solid" borderColor="highlight" bg="rgba(255, 255, 255, 0.02)" backdropFilter="blur(24px)" p={4} height="200px" data-aos={fade} data-aos-delay={delay} data-aos-anchor-placement="top-bottom" data-aos-anchor="[data-aos-id-statcard]" data-sentry-element="Flex" data-sentry-source-file="StatCard.tsx">
        <Flex h="100%" align="flex-start" data-sentry-element="Flex" data-sentry-source-file="StatCard.tsx">
          <Text textTransform="uppercase" fontSize="18px" fontWeight="600" data-sentry-element="Text" data-sentry-source-file="StatCard.tsx">
            {topText}
          </Text>
        </Flex>
        <Flex h="100%" align="flex-end" data-sentry-element="Flex" data-sentry-source-file="StatCard.tsx">
          <Text color={bottomTextColor} fontSize="56px" data-sentry-element="Text" data-sentry-source-file="StatCard.tsx">
            {bottomText}
          </Text>
        </Flex>
      </Flex>
    </MotionCard>;
};
export default StatCard;