'use client';

import { Flex, Text, useTheme } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import StandardGradient from '~/app/components/text/StandardGradient';
import { CHAIN_MAIN } from '~/constants';
export function HeroText() {
  const theme = useTheme();
  return <Flex justify="center" w="100%" align='center" ' direction={{
    base: 'column',
    lg: 'column'
  }} data-sentry-element="Flex" data-sentry-component="HeroText" data-sentry-source-file="HeroText.tsx">
      <motion.div initial={{
      scale: 1,
      opacity: 0
    }} animate={{
      opacity: [0, 0.5, 1]
    }} whileTap={{
      scale: 0.75
    }} transition={{
      duration: 1,
      delay: 0
    }} exit={{
      opacity: 0
    }} data-sentry-element="unknown" data-sentry-source-file="HeroText.tsx">
        <StandardGradient fontSize={{
        base: '2rem',
        xs: '2.5rem',
        sm: '3rem',
        lg: '3.5rem'
      }} textAlign="center" userSelect="none" fontWeight="400" zIndex={1} backgroundClip="text" data-sentry-element="StandardGradient" data-sentry-source-file="HeroText.tsx">
          {/* //HOMELESS** come back to this for OPTIMISM */}
          {CHAIN_MAIN === 8453 ? 'Maximum Aura.' : CHAIN_MAIN === 34443 ? 'Superchain Incoming' : 'Superchain Unleashed'}
          &nbsp;
        </StandardGradient>
      </motion.div>

      <motion.div initial={{
      scale: 1,
      opacity: 0
    }} animate={{
      opacity: [0, 0.5, 1]
    }} whileTap={{
      scale: 0.75
    }} transition={{
      duration: 1,
      delay: 0
    }} exit={{
      opacity: 0
    }} data-sentry-element="unknown" data-sentry-source-file="HeroText.tsx">
        {' '}
        <StandardGradient fontSize={{
        base: '3rem',
        xs: '3.5rem',
        sm: '3.5rem',
        lg: '4rem'
      }} textAlign="center" userSelect="none" fontWeight="400" zIndex={1} mt="-1rem" data-sentry-element="StandardGradient" data-sentry-source-file="HeroText.tsx">
          {/* //HOMELESS** come back to this for OPTIMISM */}{' '}
          {CHAIN_MAIN === 8453 ? 'Onchain Era.' : CHAIN_MAIN === 34443 ? 'The Sun Rises' : 'SuperSwap on OP.'}
          &nbsp;
        </StandardGradient>
      </motion.div>
    </Flex>;
}