'use client';

import { Flex, Grid, Text } from '@chakra-ui/react';
import FeaturedPoolsCard from '../FeaturedPoolsCard';
import { useV3PoolList } from '~/app/pools/lib/useV3PoolList';
import React, { memo } from 'react';
import { TokenAvatarSetInList } from '~/app/components/token/TokenAvatarSetInList';
import SectionTitleText from '~/app/home/components/SectionTitleText';
import AprToolTipNoRange from '~/app/components/tooltips/apr-tooltip/AprToolTipNoRange';
import FeaturedPoolContent from '../FeaturedPoolsCard/Content';
import { CHAIN_MAIN } from '~/constants';
export default function FeaturedPoolsGrid() {
  const {
    poolsWithLiquidity
  } = useV3PoolList();
  const featuredPoolsArrayBase = ['8453-0x273fdfe6018230f188741d7f93d4ab589bd26197', '8453-0x74cb6260be6f31965c239df6d6ef2ac2b5d4f020', '8453-0x75a50f51d49045d7f00e660d0ad7244ccfe4d372'];
  const featuredPoolsArrayMode = ['34443-0x564227fb8a192752446839950f8119d94d941774', '34443-0xa1c6800788482ba0eeb85f47322bb789986ee2f3', '34443-0x20cf165da8c40f173b5c75d9e085b3c76a9b75a5'];

  // Select the correct array based on CHAIN_MAIN
  //HOMELESS** come back to this for OPTIMISM
  const featuredPoolsArray = CHAIN_MAIN === 8453 ? featuredPoolsArrayBase : featuredPoolsArrayMode;
  const featuredPools = poolsWithLiquidity.filter(pool => featuredPoolsArray.includes(pool.id));
  return <Flex direction="column" minW="100%" w="100%" data-sentry-element="Flex" data-sentry-component="FeaturedPoolsGrid" data-sentry-source-file="index.tsx">
      <Flex justify="flex-start" w="100%" data-aos-id-featuredpoolstitle data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <SectionTitleText aosAnchor="[data-aos-id-featuredpoolstitle]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">
          Featured Pools
        </SectionTitleText>
      </Flex>

      <Flex w="100%" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Grid templateColumns={{
        base: '1fr',
        lg: 'repeat(2, 1fr)',
        xxl: 'repeat(3, 1fr)'
      }} gap={{
        base: 2,
        lg: 16
      }} maxW={{
        base: '2xl',
        lg: 'none'
      }} mx="auto" alignItems="center" justifyItems="center" data-aos-id-featuredpools data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {featuredPools.map((pool, index) => <FeaturedPoolsCard key={pool.id} type={index % 2 === 0 ? 'light' : 'dark'} delay={`${index * 200}`}>
              <FeaturedPoolContent pool={pool} />
            </FeaturedPoolsCard>)}
        </Grid>
      </Flex>
    </Flex>;
}