'use client';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion } from 'framer-motion';
import { useTheme, Flex, Text } from '@chakra-ui/react';
import { CHAIN_MAIN } from '~/constants';
export default function WelcomeHome() {
  useEffect(() => {
    AOS.init({
      once: false,
      mirror: true
    });
  }, []);
  const theme = useTheme();
  return <Flex w="100%" my="0rem" direction="column" justify="center" align="center" data-sentry-element="Flex" data-sentry-component="WelcomeHome" data-sentry-source-file="WelcomeHome.tsx">
      <Flex w="100%" align="center" direction="column" justify="center" data-sentry-element="Flex" data-sentry-source-file="WelcomeHome.tsx">
        <motion.div initial={{
        opacity: 0
      }} whileInView={{
        opacity: 1
      }} transition={{
        ease: 'easeOut',
        duration: 1,
        delay: 0.5
      }} viewport={{
        once: true
      }} data-sentry-element="unknown" data-sentry-source-file="WelcomeHome.tsx">
          <Text fontSize={{
          base: '1.8rem',
          xs: '2.2rem',
          lg: '2.5rem'
        }} data-sentry-element="Text" data-sentry-source-file="WelcomeHome.tsx">
            <>
              {CHAIN_MAIN === 10 && 'Super'}
              {CHAIN_MAIN === 8453 && 'Base'}
              <span style={{
              color: theme.colors.primary
            }}>Swap</span>
              {CHAIN_MAIN === 34443 && 'Mode'}.
            </>
          </Text>
        </motion.div>
      </Flex>
      <motion.div initial={{
      opacity: 0
    }} whileInView={{
      opacity: 1,
      transition: {
        ease: 'easeIn',
        duration: 1.5,
        delay: 1
      }
    }} viewport={{
      once: true
    }} data-sentry-element="unknown" data-sentry-source-file="WelcomeHome.tsx">
        <Text fontSize={{
        base: '2rem',
        xs: '2.3rem',
        lg: '3rem'
      }} data-sentry-element="Text" data-sentry-source-file="WelcomeHome.tsx">
          <>
            <span style={{
            color: theme.colors.primary
          }}>Welcome </span> Home
            <span style={{
            color: theme.colors.primary
          }}>.</span>
          </>
        </Text>
      </motion.div>
    </Flex>;
}