'use client';

import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Image from 'next/image';
import { Flex, Text, useBreakpointValue, useTheme } from '@chakra-ui/react';
import banner from '~/public/logo/BSWAP.webp';
import bannerMode from '~/public/logo/SMDLOGO.webp';
import { CHAIN_MAIN } from '~/constants';
import bannerOp from '~/public/logo/superswappng.png';
export const AnimatedBaseSwap = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const controls = useAnimation();
  const theme = useTheme();
  const getImageSource = () => {
    switch (CHAIN_MAIN) {
      case 8453:
        return banner;
      case 34443:
        return bannerMode;
      case 10:
        return bannerOp;
      default:
        return banner;
    }
  };
  const selectedImage = getImageSource();
  const startPulseAnimation = () => {
    controls.start({
      boxShadow: ['0 0 4px rgba(255, 255, 255, 0.25)', '0 0 12px rgba(255, 255, 255, 0.5)', '0 0 12px #fff', `0 0 12px ${theme.colors.highlight}`, `0 0 24px ${theme.colors.highlight}`, `0 0 18px ${theme.colors.primary}`, `0 0 12px ${theme.colors.primary}`, `0 0 24px ${theme.colors.primary}`],
      transition: {
        duration: 7,
        repeat: 1,
        repeatType: 'reverse' // are there better options for this to smooth it out?
      }
    });
  };
  const xValues = useBreakpointValue({
    base: [-150, 100, -30, 0],
    lg: [-350, 200, -50, 0]
  });
  return <Flex direction="column" justify="center" align="center" data-sentry-element="Flex" data-sentry-component="AnimatedBaseSwap" data-sentry-source-file="AnimatedBaseSwap.tsx">
      <motion.div initial={{
      scale: 0,
      opacity: 0
    }} animate={{
      scale: [0, 1, 1.15, 1],
      rotate: -360,
      x: xValues,
      opacity: [0, 0.25, 0.5, 1]
    }} whileTap={{
      scale: 0.75
    }} transition={{
      duration: 1.5,
      delay: 0
    }} exit={{
      opacity: 0
    }} style={{
      cursor: 'pointer'
    }} onAnimationComplete={() => {
      setTimeout(() => {
        setIsAnimated(true);
        startPulseAnimation();
      }, 250);
    }} data-sentry-element="unknown" data-sentry-source-file="AnimatedBaseSwap.tsx">
        <motion.div animate={controls} style={{
        borderRadius: '50%'
      }} data-sentry-element="unknown" data-sentry-source-file="AnimatedBaseSwap.tsx">
          <Image src={selectedImage} alt="logo" priority className="homeperiodimage" style={{
          boxShadow: isAnimated ? 'none' : `0 0 12px ${theme.colors.primary}`
        }} data-sentry-element="Image" data-sentry-source-file="AnimatedBaseSwap.tsx" />
        </motion.div>
      </motion.div>
    </Flex>;
};